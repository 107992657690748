<template>
	<div>
		{{ this.currentDate }}
		<v-dialog
			v-model="errDialog"
			@click:outside="dialog_outside"
			max-width="600"
		>
			<v-card>
				<v-card-title
					class="text-h6 text-md-h5 pt-2 pt-md-4 pb-2 pb-md-4 titleColor"
					color="primary"
				>
					貸出可能数を超過しています
					<v-spacer></v-spacer>

					<v-btn icon @click="errDialog = false">
						<v-icon color="white">mdi-close-circle</v-icon>
					</v-btn>
				</v-card-title>

				<!-- <v-card-text> -->
				<div>
					<v-simple-table>
						<template v-slot:default>
							<thead>
								<tr>
									<th class="text-left">Model</th>
									<th class="text-left">Stock Calendar</th>
								</tr>
							</thead>
							<tbody>
								<tr
									v-for="(item, index) in viewOutputModel"
									:key="item.id + item.model + index"
								>
									<td>{{ item.model }}</td>
									<td>
										<v-btn
											color="red darken-4"
											elevation="0"
											outlined
											class="rounded-0"
											@click="dialogCalClick(item)"
											>超過個数</v-btn
										>
									</td>
								</tr>
							</tbody>
						</template>
					</v-simple-table>
				</div>
			</v-card>
		</v-dialog>
		<v-dialog
			v-model="changeDialog"
			@click:outside="dialog_outside"
			max-width="600"
		>
			<v-card>
				<v-card-title class="text-h6 pa-4 titleColor" color="primary">
					貸出日を延長しますか？
					<v-spacer></v-spacer>
				</v-card-title>
				<v-card-text class="pr-4 pl-4 pt-4 pb-0">
					<p class="text-subtitle-1 text-center mb-0">
						指定した日付へ返却日を延長することが可能です。
					</p>
				</v-card-text>
				<v-card-actions class="pa-4">
					<v-row class="ma-0">
						<v-col class="pa-0 mr-2"
							><v-btn
								@click="changeDialog = false"
								outlined
								block
								color="#262626"
								class="rounded-0"
								>Close</v-btn
							></v-col
						>
						<v-col class="pa-0 ml-2"
							><v-btn
								@click="changeDates"
								outlined
								block
								color="#262626"
								class="rounded-0"
								>Change</v-btn
							></v-col
						>
					</v-row>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-menu
			ref="menu"
			v-model="menu"
			:close-on-content-click="false"
			:return-value.sync="date"
			transition="scale-transition"
			offset-y
			min-width="auto"
			class="menuStyle"
		>
			<template v-slot:activator="{ on, attrs }">
				<v-card>
					<v-form ref="form" v-model="vForm">
						<v-card-title
							class="text-h6 text-md-h5 pt-2 pt-md-4 pb-2 pb-md-4 titleColor"
						>
							返却期限延長
							<v-spacer></v-spacer>

							<v-btn
								icon
								@click="$store.commit('set_dialogInfo_return_due', false)"
							>
								<v-icon color="white">mdi-close-circle</v-icon>
							</v-btn>
						</v-card-title>
						<v-text-field
							v-model="date"
							@click="caldate"
							label="返却日時を入力してください"
							prepend-icon="mdi-calendar"
							readonly
							v-bind="attrs"
							v-on="on"
							class="menuStyle"
							:rules="[(v) => !!v || '必ず入力してください']"
						></v-text-field>
					</v-form>
					<v-divider></v-divider>
					<v-card-actions>
						<v-spacer></v-spacer>
						<v-btn
							outlined
							color="#262626"
							style="text-transform: none"
							@click="enterDates"
							class="datetimeex rounded-0"
							:disabled="!vForm"
							>CHANGE</v-btn
						>
					</v-card-actions>
				</v-card>
			</template>
			<v-date-picker
				v-model="date"
				no-title
				scrollable
				color="transparent"
				:min="this.ListInfo_A[0].return_date"
				flat
			>
				<v-spacer></v-spacer>
				<v-btn
					text
					color="#262626"
					elevation="0"
					@click="(menu = false), $refs.form.reset()"
				>
					Cancel
				</v-btn>
				<v-btn
					text
					color="#262626"
					elevation="0"
					@click="$refs.menu.save(date)"
				>
					OK
				</v-btn>
			</v-date-picker>
		</v-menu>
		<v-dialog
			v-model="dialogCalendar"
			@click:outside="dialog_outside"
			max-width="600px"
		>
			<v-card flat>
				<v-card-title
					class="text-h6 text-md-h5 pt-2 pt-md-4 pb-2 pb-md-4 titleColor"
					color="primary"
					>超過個数を確認
					<v-spacer></v-spacer>

					<v-btn icon @click="dialogCalendar = false">
						<v-icon color="white">mdi-close-circle</v-icon>
					</v-btn>
				</v-card-title>
				<v-card-text>
					<v-sheet>
						<v-toolbar flat class="pa-0 ma-0 mb-5">
							<v-btn
								fab
								text
								small
								color="grey darken-2"
								class="rounded-0"
								@click="$refs.calendar.prev(), prev()"
							>
								<v-icon small> mdi-chevron-left </v-icon>
							</v-btn>
							<v-spacer></v-spacer>
							<!-- <v-btn outlined small class="ma-4" @click="setToday">
									Today
								</v-btn> -->
							<v-toolbar-title>{{ datetitle }}</v-toolbar-title>
							<v-spacer></v-spacer>
							<v-btn
								fab
								text
								small
								color="grey darken-2"
								class="rounded-0"
								@click="$refs.calendar.next(), next()"
							>
								<v-icon small> mdi-chevron-right </v-icon>
							</v-btn>
						</v-toolbar>
						<v-alert text prominent type="error" icon="mdi-cloud-alert">
							指定した日付、{{
								this.date
							}}では貸出可能数を超過しており貸出の手続きを完了することができません。下記のカレンダーから超過している日付を確認して、日付の変更をしてください。
						</v-alert>
					</v-sheet>
					<div>
						<v-sheet height="500px">
							<v-calendar
								ref="calendar"
								v-model="value"
								type="month"
								locale="ja-jp"
								class="rentCal ma-0 pa-0"
								color="white"
								:day-format="(timestamp) => new Date(timestamp.date).getDate()"
								:month-format="
									(timestamp) => new Date(timestamp.date).getMonth() + 1 + ' /'
								"
								:events="this._resultmonth"
								:event-height="20"
							>
								<template v-slot:event="{ event, start }">
									<v-card
										v-if="start"
										:color="color"
										elevation="0"
										outlined
										class="transparentCard ma-0 pa-0"
									>
										<v-card-text
											class="pa-0"
											v-html="event.name"
											align="center"
											style="font-size: 1.5em; color: #ffffff"
										></v-card-text>
									</v-card> </template
							></v-calendar>
						</v-sheet>
					</div>
				</v-card-text>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
	import firebase from "firebase";
	import moment from "moment";
	import _ from "lodash";

	export default {
		name: "ReturnDue",
		props: { ListInfo: Array, ListInfo_A: Array },
		components: {},
		methods: {
			caldate() {
				if (!this.date) {
					this.date = this.ListInfo_A[0].return_date;
				}
			},
			dialogCalClick(item) {
				this.dialogCalendar = true;
				var clickitem = item;
				this._itemcache = clickitem;
				// 当月のデータを出力する
				var _resultmonth = [];
				console.log(this._dateFilter);
				const resultday = this._dateFilter.split("-");
				const startresult = resultday[0] + "-" + resultday[1] + "-01";
				const endresult =
					resultday[0] + "-" + resultday[1] + "-" + moment().daysInMonth();

				var begin_month = moment(startresult).format("YYYY-MM-DD");
				var end_month = moment(endresult).format("YYYY-MM-DD");
				console.log(begin_month, end_month);

				for (
					var dm = new Date(begin_month);
					dm <= new Date(end_month);
					dm.setDate(dm.getDate() + 1)
				) {
					let item = {
						name: clickitem.stock + "/" + clickitem.stock,
						start: moment(dm).format("YYYY-MM-DD"),
						end: moment(dm).format("YYYY-MM-DD"),
						color: "#BDBDBD",
					};
					_resultmonth.push(item);
					// console.log(item);
				}
				// this._resultmonth = _resultmonth;
				console.log(_resultmonth);

				// 当月のデータを出力する ここまで

				// 超過個数 カレンダー用に再マップ

				var _overItems = _.map(this._overItems, (el) => {
					return {
						hash: _.get(el, "hash"),
						model: _.get(el, "model"),
						stock: _.get(el, "stock"),
						name: Math.abs(_.get(el, "stock") - _.get(el, "name")),
						start: _.get(el, "start"),
						end: _.get(el, "end"),
						color: "#E57373",
					};
				});
				console.log(_overItems);
				this._overItems_result = _overItems;

				// 超過個数 カレンダー用に再マップ ここまで

				// 超過していない カレンダー用に再マップ

				var _notoverItems = _.map(this._notoverItems, (el) => {
					return {
						hash: _.get(el, "hash"),
						model: _.get(el, "model"),
						modelraw: _.get(el, "modelraw"),
						stock: _.get(el, "stock"),
						name:
							`${+_.get(el, "stock") - _.get(el, "name")}` +
							"/" +
							_.get(el, "stock"),
						start: _.get(el, "start"),
						end: _.get(el, "end"),
						color: "#AED581",
					};
				});
				console.log(_notoverItems);
				var _notoverItemsFilter = _.filter(_notoverItems, (el) => {
					return el.model == clickitem.model;
				});
				console.log(_notoverItemsFilter);
				this._notoverItemsFilter = _notoverItemsFilter;

				// 超過していない カレンダー用に再マップ ここまで

				// カレンダー用全データをマージ
				// 最初に超過していない日付をマージ

				var resultmonthCal_first = _.merge(
					_.keyBy(_resultmonth, "start"),
					_.keyBy(_notoverItemsFilter, "start")
				);
				console.log(resultmonthCal_first);
				this.resultmonthCal_first = resultmonthCal_first;

				//次に超過している日付をマージ

				var resultmonthCal_second = _.merge(
					_.keyBy(resultmonthCal_first, "start"),
					_.keyBy(_overItems, "start")
				);
				console.log(resultmonthCal_second);
				this.resultmonthCal_second = resultmonthCal_second;

				// カレンダーに代入

				this._resultmonth = _.values(resultmonthCal_second);
			},
			prev() {
				var clickitem = this._itemcache;
				var _resultmonth = [];
				console.log(this.value);

				const resultday = this.value.split("-");
				const startresult = resultday[0] + "-" + resultday[1] + "-01";
				const endresult = moment(
					resultday[0] + "-" + resultday[1],
					"YYYY-MM"
				).daysInMonth();
				console.log(endresult);

				var begin_month = moment(startresult).format("YYYY-MM-DD");
				var end_month = moment(
					resultday[0] + "-" + resultday[1] + "-" + endresult
				).format("YYYY-MM-DD");
				console.log(begin_month, end_month);

				for (
					var dm = new Date(begin_month);
					dm <= new Date(end_month);
					dm.setDate(dm.getDate() + 1)
				) {
					let item = {
						name: clickitem.stock + "/" + clickitem.stock,
						start: moment(dm).format("YYYY-MM-DD"),
						end: moment(dm).format("YYYY-MM-DD"),
						color: "#BDBDBD",
					};
					_resultmonth.push(item);
					console.log(item);
				}
				// this._resultmonth = _resultmonth;
				console.log(_resultmonth);

				var resultmonthCal_first = _.merge(
					_.keyBy(_resultmonth, "start"),
					_.keyBy(this._notoverItemsFilter, "start")
				);
				console.log(resultmonthCal_first);
				// this.resultmonthCal_first = resultmonthCal_first;

				//次に超過している日付をマージ

				var resultmonthCal_second = _.merge(
					_.keyBy(resultmonthCal_first, "start"),
					_.keyBy(this._overItems_result, "start")
				);
				console.log(resultmonthCal_second);
				// this.resultmonthCal_second = resultmonthCal_second;

				// カレンダーに代入

				this._resultmonth = _.values(resultmonthCal_second);
			},
			next() {
				var clickitem = this._itemcache;
				var _resultmonth = [];
				console.log(this.value);

				const resultday = this.value.split("-");
				const startresult = resultday[0] + "-" + resultday[1] + "-01";
				const endresult = moment(
					resultday[0] + "-" + resultday[1],
					"YYYY-MM"
				).daysInMonth();
				console.log(endresult);

				var begin_month = moment(startresult).format("YYYY-MM-DD");
				var end_month = moment(
					resultday[0] + "-" + resultday[1] + "-" + endresult
				).format("YYYY-MM-DD");
				console.log(begin_month, end_month);

				for (
					var dm = new Date(begin_month);
					dm <= new Date(end_month);
					dm.setDate(dm.getDate() + 1)
				) {
					let item = {
						name: clickitem.stock + "/" + clickitem.stock,
						start: moment(dm).format("YYYY-MM-DD"),
						end: moment(dm).format("YYYY-MM-DD"),
						color: "#BDBDBD",
					};
					_resultmonth.push(item);
					console.log(item);
				}
				// this._resultmonth = _resultmonth;
				console.log(_resultmonth);

				var resultmonthCal_first = _.merge(
					_.keyBy(_resultmonth, "start"),
					_.keyBy(this._notoverItemsFilter, "start")
				);
				console.log(resultmonthCal_first);
				// this.resultmonthCal_first = resultmonthCal_first;

				//次に超過している日付をマージ

				var resultmonthCal_second = _.merge(
					_.keyBy(resultmonthCal_first, "start"),
					_.keyBy(this._overItems_result, "start")
				);
				console.log(resultmonthCal_second);
				// this.resultmonthCal_second = resultmonthCal_second;

				// カレンダーに代入

				this._resultmonth = _.values(resultmonthCal_second);
			},
			enterDates() {
				// Firebase
				// var db = firebase.firestore();

				// 選択しているアイテムを取り出す
				const selecteditems = this.ListInfo_A;
				const selectedItemsNest = this.ListInfo;
				var _sortItems = _.sortBy(selectedItemsNest, "model");
				console.log(selecteditems, selectedItemsNest, _sortItems);

				let stock = _.filter(this._sortresultRental, (item) => {
					return item.model === "ZCAM E2-M4";
				});
				console.log(stock);

				// for (let i = 0; i < selectedItemsNest.length; i++) {}

				// 選択しているデータを取り出す
				// キャッシュのために代入
				var _resultconvertItems_t = this._resultconvertItems_t;
				var _resultconvertItems_f = this._resultconvertItems_f;
				console.log(_resultconvertItems_t);
				console.log(_resultconvertItems_f);
				var _result = [];
				var _result_f = [];

				// 日付の後ろを取得するために代入
				var _resultconvertItems_t_endlength =
					this._resultconvertItems_t.length - 1;

				var _resultconvertItems_f_endlength =
					this._resultconvertItems_f.length - 1;

				// 先頭の日付
				var startday = _resultconvertItems_t[0].start;
				var startday_f = _resultconvertItems_f[0].start;

				// 末尾の日付
				var endday =
					_resultconvertItems_t[_resultconvertItems_t_endlength].start;
				var endday_f =
					_resultconvertItems_f[_resultconvertItems_f_endlength].start;

				// 日付が選択された場合
				if (this.date) {
					endday = this.date;
				}
				console.log(startday, endday);

				// moment.jsで変換
				var begin = moment(startday).format("YYYY-MM-DD");
				var end = moment(endday).format("YYYY-MM-DD");

				var begin_f = moment(startday_f).format("YYYY-MM-DD");
				var end_f = moment(endday_f).format("YYYY-MM-DD");
				console.log(begin_f, end_f);

				// 配列を生成（keyby）
				var _keybyresult_t = _(_resultconvertItems_t).groupBy("model").value();
				console.log(_keybyresult_t);

				// 配列を生成（keyby）
				var _keybyresult_f = _(_resultconvertItems_f).groupBy("model").value();
				console.log(_keybyresult_f);

				// 配列を生成 _t
				for (let i = 0; i < Object.keys(_keybyresult_t).length; i++) {
					console.log(Object.keys(_keybyresult_t)[i]);

					for (
						var d = new Date(begin);
						d <= new Date(end);
						d.setDate(d.getDate() + 1)
					) {
						let splitStr = Object.keys(_keybyresult_t).sort()[i].split("#");
						let item = {
							hash: splitStr[0] + "#" + moment(d).format("YYYY-MM-DD"),
							model: splitStr[0],
							stock: Number(_sortItems[i].stock),
							name: Number(splitStr[1]),
							start: moment(d).format("YYYY-MM-DD"),
							end: moment(d).format("YYYY-MM-DD"),
						};
						_result.push(item);
						// console.log(_result);
					}
				}
				console.log(_result);

				// 超過していないデータ出力
				// 現状の出力方法だと予約が入っている最後の日付まで連続してしまう

				for (let i = 0; i < Object.keys(_keybyresult_f).length; i++) {
					let length = Object.values(_keybyresult_f)[i].length;
					console.log(length);
					for (
						var df = new Date(begin_f);
						df <= new Date(end_f);
						df.setDate(df.getDate() + 1)
					) {
						let splitStr = Object.keys(_keybyresult_f).sort()[i].split("#");
						// // let rawlength = Object.keys(_keybyresult_f).length;
						// let length = Object.values(_keybyresult_f)[i].length;
						// // console.log(rawlength);
						// console.log(length);
						let stock = _.filter(this._sortresultRental, (item) => {
							return item.model === splitStr[0];
						});
						// console.log(splitStr[0]);

						let item = {
							hash: splitStr[0] + "#" + moment(df).format("YYYY-MM-DD"),
							model: splitStr[0],
							stock: Number(stock[0].stock),
							name: Object.values(_keybyresult_f)[i][0].name,
							start: moment(df).format("YYYY-MM-DD"),
							end: moment(df).format("YYYY-MM-DD"),
						};
						_result_f.push(item);
						// console.log(_result);
					}
				}
				console.log(_result_f);

				// 配列を生成 ここまで

				// 超過していないデータ出力 修正版
				// 配列を生成テスト

				var _key = [];
				var _arr = [];
				var _model = [];
				var _resultMerged = [];
				var _result_fm = [];

				for (let i = 0; i < Object.keys(_keybyresult_f).length; i++) {
					let splitStr = Object.keys(_keybyresult_f).sort()[i].split("#");
					let stock = _.filter(this._sortresultRental, (item) => {
						return item.model === splitStr[0];
					});
					// console.log(splitStr, stock);
					for (let j = 0; j < Object.values(_keybyresult_f)[i].length; j++) {
						console.log(Number(stock[0].stock), j);
						_key.push(Object.values(_keybyresult_f)[i][j]);
						_arr.push({ stock: Number(stock[0].stock) });
						_model.push({ model: splitStr[0] });
					}
				}
				_resultMerged = _.merge(_key, _arr);
				_result_fm = _.merge(_resultMerged, _model);

				console.log(_result_fm);

				// 選択しているデータを取り出す ここまで

				// マージ

				var _merged = _.concat(
					JSON.parse(JSON.stringify(_resultMerged)),
					JSON.parse(JSON.stringify(_result))
				);
				console.log(_merged);

				var _mergedkey = _(_merged)
					.groupBy("hash")
					.map((objs, key) => ({
						hash: key,
						model: objs[0].model,
						modelraw: objs[0].modelraw,
						stock: objs[0].stock,
						name: _.sumBy(objs, "name"),
						start: objs[0].start,
						end: objs[0].end,
					}))
					.value();
				console.log(_mergedkey);

				// マージ ここまで

				// ストック超過
				var _overItems = _.filter(_mergedkey, (el) => {
					return el.name > el.stock;
				});
				console.log(_overItems);
				this._overItems = _overItems;

				// ストック超過 ここまで

				// ストック超過してない

				var _notoverItems = _.filter(_mergedkey, (el) => {
					return el.name <= el.stock;
				});

				console.log(_notoverItems);
				this._notoverItems = _notoverItems;

				// ストック量化してない ここまで

				// ダイアログを表示するかどうか

				if (_overItems.length > 0) {
					this.errDialog = true;
				} else if (_overItems.length <= 0) {
					this.changeDialog = true;
				}

				// ダイアログを表示するかどうか ここまで

				// ダイアログに機材名を出力する

				var _outputModel = _.merge(_.keyBy(_overItems, "model"));
				this.viewOutputModel = _outputModel;
				console.log(this.viewOutputModel);

				// ダイアログに機材名を出力する ここまで

				// this.errDialog = true;
				// var _convertSelectedItems = [];

				// const selecteditems = this.ListInfo_A;
				// const selectedItemsNest = this.ListInfo;

				// for (let i = 0; i < selectedItemsNest.length; i++) {
				// 	var id_s = selecteditems[0].id;
				// 	var model_s = selectedItemsNest[i].model;
				// 	var name_s = Number(selectedItemsNest[i].stock);
				// 	var start_s = selecteditems[0].checkout_date;
				// 	var end_s = this.date;
				// 	var status_s = selecteditems[0].status;
				// 	var user_s = selecteditems[0].user;

				// 	console.log(name_s);

				// 	var splitstartDate = start_s.split("-");
				// 	var splitendDate = end_s.split("-");

				// 	var toDateStart = new Date(
				// 		splitstartDate[0],
				// 		splitstartDate[1] - 1,
				// 		splitstartDate[2]
				// 	);
				// 	var toDateEnd = new Date(
				// 		splitendDate[0],
				// 		splitendDate[1] - 1,
				// 		splitendDate[2]
				// 	);

				// 	for (
				// 		var d = toDateStart;
				// 		d <= toDateEnd;
				// 		d.setDate(d.getDate() + 1)
				// 	) {
				// 		_convertSelectedItems.push({
				// 			id: id_s,
				// 			model: model_s,
				// 			name: name_s,
				// 			user: user_s,
				// 			status: status_s,
				// 			start: moment(d).format("YYYY-MM-DD"),
				// 			end: moment(d).format("YYYY-MM-DD"),
				// 		});
				// 		_convertSelectedItems = _.map(_convertSelectedItems, (el) => {
				// 			return {
				// 				hash: _.get(el, "model") + _.get(el, "start"),
				// 				id: _.get(el, "id"),
				// 				model: _.get(el, "model"),
				// 				name: _.get(el, "name"),
				// 				user: _.get(el, "user"),
				// 				status: _.get(el, "status"),
				// 				start: _.get(el, "start"),
				// 				end: _.get(el, "end"),
				// 			};
				// 		});
				// 	}
				// }
				// console.log(_convertSelectedItems);

				// var mergedItems = _.concat(
				// 	JSON.parse(JSON.stringify(_convertSelectedItems)),
				// 	JSON.parse(JSON.stringify(this._resultconvertItems))
				// );

				// console.log(mergedItems);

				// var _keyMerge = _(mergedItems)
				// 	.groupBy("hash")
				// 	.map((objs, key) => ({
				// 		id: key,
				// 		model: objs[0].model,
				// 		name: _.sumBy(objs, "name"),
				// 		start: objs[0].start,
				// 	}))
				// 	.value();

				// console.log(_keyMerge);

				// var _overItems = _.filter(_keyMerge, (el) => {
				// 	return el.name > el.stock;
				// });

				// console.log(_overItems);

				// var _notoverItems = _.filter(_keyMerge, (el) => {
				// 	return el.name < el.stock;
				// });

				// console.log(_notoverItems);

				// var _equalItems = _.filter(_keyMerge, (el) => {
				// 	return el.name == el.stock;
				// });

				// console.log(_equalItems);

				// var _outputModel = _.merge(_.keyBy(_overItems, "model"));
				// this.viewOutputModel = _outputModel;
				// console.log(this.viewOutputModel);

				// var resultmonthCal = _.merge(
				// 	_.keyBy(this.resultmonthCopy, "start"),
				// 	_.keyBy(this.saveCalValues, "start")
				// );

				// this.resultmonthCal = _.values(resultmonthCal);

				// if (_overItems.length > 0) {
				// 	this.errDialog = true;
				// } else if (_overItems.length <= 0) {
				// 	this.errDialog = false;
				// }
			},

			changeDates: function () {
				var db = firebase.firestore();

				const selected = this.ListInfo_A.indexOf();
				const saveID = this.ListInfo_A[selected + 1].id;

				// console.log(this.ListInfo_A);

				db.collection("rentals")
					.doc(saveID)
					.update({
						return_date: this.date,
					})
					.then(() => {
						db.collection("calendar")
							.where("id", "==", saveID)
							.get()
							.then((querySnapshot) => {
								querySnapshot.forEach((doc) =>
									doc.ref.update({
										end: this.date,
									})
								);
								this.$router.go({
									path: this.$router.currentRoute.path,
									force: true,
								});
							});
					});
			},

			resetChildForm: function () {
				this.$refs.form.reset();
				console.log("ok");
			},
		},
		computed: {
			_calIDFilter() {
				return this.ListInfo_A[0].id;
			},
			_dateFilter() {
				return moment().format("YYYY-MM-DD");
			},
		},
		data: () => ({
			menu: false,
			dates: [],
			date: "",
			value: "",

			//現在の年月日
			currentDate: "",

			// カレンダーデータベース
			db_calendar: [],

			// レンタルデータベース
			db_rental: [],

			// カレンダー整形
			calendarItems: [],
			_resultmonth: [],
			_resultconvertItems_t: [],
			_resultconvertItems_f: [],
			_overItems_result: [],
			_notoverItemsFilter: [],
			resultmonthCal_first: [],
			resultmonthCal_second: [],
			_sortresultRental: [],
			_overItems: [],
			_notoverItems: [],
			_itemcache: "",
			dialogCalendar: false,
			errDialog: false,
			changeDialog: false,

			viewOutputModel: [],
			viewOutputLength: false,
		}),
		mounted() {
			this.value = moment().format("yyyy-MM-DD");

			var _convertItems = [];
			var db = firebase.firestore();

			// レンタル全体のデータ

			db.collection("equipments")
				.get()
				.then((querySnapshot) => {
					//データ格納
					querySnapshot.forEach((doc) => this.db_rental.push(doc.data()));

					console.log(this.db_rental);
					var _resultRental = this.db_rental;
					var _sortresultRental = _.sortBy(_resultRental, "model");
					console.log(_sortresultRental);
					this._sortresultRental = _sortresultRental;
				});

			// 選択しているアイテムを取り出す
			const selecteditems = this.ListInfo_A;
			const selectedItemsNest = this.ListInfo;
			var _sortItems = _.sortBy(selectedItemsNest, "model");
			console.log(selecteditems, selectedItemsNest, _sortItems);

			// カレンダーのデータ取り出し
			db.collection("calendar")
				.where("status", "==", false)
				.get()
				.then((querySnapshot) => {
					//データ格納
					querySnapshot.forEach((doc) => this.db_calendar.push(doc.data()));

					// カレンダーデータ整形
					const calendarDBItems = this.db_calendar;

					for (let i = 0; i < calendarDBItems.length; i++) {
						var id_c = calendarDBItems[i].id;
						var model_c = calendarDBItems[i].model;
						var name_c = calendarDBItems[i].name;
						var user_c = calendarDBItems[i].user;
						var status_c = calendarDBItems[i].status;
						var start_c = calendarDBItems[i].start;
						var end_c = calendarDBItems[i].end;

						var splitstartDate = start_c.split("-");
						var splitendDate = end_c.split("-");

						var toDateStart = new Date(
							splitstartDate[0],
							splitstartDate[1] - 1,
							splitstartDate[2]
						);
						var toDateEnd = new Date(
							splitendDate[0],
							splitendDate[1] - 1,
							splitendDate[2]
						);

						for (
							var d = toDateStart;
							d <= toDateEnd;
							d.setDate(d.getDate() + 1)
						) {
							_convertItems.push({
								id: id_c,
								model: model_c,
								name: name_c,
								user: user_c,
								status: status_c,
								start: moment(d).format("YYYY-MM-DD"),
								end: moment(d).format("YYYY-MM-DD"),
							});
							_convertItems = _.map(_convertItems, (el) => {
								return {
									hash: _.get(el, "model") + "#" + _.get(el, "start"),
									id: _.get(el, "id"),
									model: _.get(el, "model"),
									name: _.get(el, "name"),
									user: _.get(el, "user"),
									status: _.get(el, "status"),
									start: _.get(el, "start"),
									end: _.get(el, "end"),
								};
							});
						}
					}

					var _resultconvertItems_t = _.filter(_convertItems, (el) => {
						return el.id == this._calIDFilter;
					});

					var _resultconvertItems_f = _.filter(_convertItems, (el) => {
						return el.id != this._calIDFilter;
					});

					_resultconvertItems_t = _(_resultconvertItems_t)
						.groupBy("hash")
						.map((objs, key) => ({
							hash: key,
							// model: objs[0].model,
							model: objs[0].model + "#" + objs[0].name,
							name: _.sumBy(objs, "name"),
							start: objs[0].start,
							end: objs[0].start,
						}))
						.sortBy("hash")
						.value();
					console.log(_resultconvertItems_t);

					this._resultconvertItems_t = _resultconvertItems_t;

					_resultconvertItems_f = _(_resultconvertItems_f)
						.groupBy("hash")
						.map((objs, key) => ({
							hash: key,
							// model: objs[0].model,
							model: objs[0].model + "#" + objs[0].name,
							name: _.sumBy(objs, "name"),
							start: objs[0].start,
							end: objs[0].start,
						}))
						.sortBy("hash")
						.value();
					console.log(_resultconvertItems_f);

					// var array = [2, 3, 4, 5, 8, 9, 12, 13, 14, 15, 16, 17, 20];
					// var testarray = [
					// 	"2022-07-28",
					// 	"2022-07-29",
					// 	"2022-07-20",
					// 	"2022-07-21",
					// ];
					var dateformat = "YYYY-MM-DD";

					// 配列を生成（keyby）
					var _keybyresult_t = _(_resultconvertItems_t)
						.groupBy("model")
						.value();
					console.log(_keybyresult_t);

					// 配列を生成（keyby）
					var _keybyresult_f = _(_resultconvertItems_f)
						.groupBy("model")
						.value();
					console.log(_keybyresult_f);

					for (let i = 0; i < _keybyresult_f.length; i++) {
						console.log("test");
					}
					var result = _resultconvertItems_f.reduce(function (acc, val) {
						console.log(val.start);
						console.log(acc);
						var present,
							date = moment(val.start, dateformat);
						acc.forEach(function (arr) {
							if (present) return;
							if (
								arr.indexOf(
									date.clone().subtract(1, "day").format(dateformat)
								) > -1 ||
								arr.indexOf(date.clone().add(1, "day").format(dateformat)) > -1
							) {
								present = true;
								arr.push(val.start);
							}
						});
						if (!present) acc.push([val.start]);
						return acc;
					}, []);

					console.log(result);

					this._resultconvertItems_f = _resultconvertItems_f;
				});
		},
	};
</script>
